import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { BlogReducer } from "../reducers/BlogReducer";
import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  blog: BlogReducer,
});

let initialState = {};
const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
