import "./IndTrade.css";
import star from "../../assets/Frame 1000002655.png";
import star2 from "../../assets/Frame 1000002656.png";
import graph from "../../assets/Graph.png";
import graph2 from "../../assets/Graph (1).png";
import IndcesTradingView from "../IndcesTradingView";
import { Link } from "react-router-dom";

const IndecesTrade = () => {
  return (
    <div className="indecestrade row">
      <div className="indecestradecontent col-12 col-md-12 col-lg-6">
        <div className="indtiltle">
          <div className="indhead">
            <b className="trading">Trading</b>
            <div className="indsubtitle">
              <div className="tradeOnOver1">Trade Over 80+ Indices
              </div>
              <div className="radeOnMore1">
              Get Into trading with over 80 indices, including major global markets like the FTSE 100, and regional ones from Australia, Asia-Pacific, the US, and Europe.

              </div>
            </div>
          </div>
        </div>
        <Link
          className="getStartButton1"
          to="#">
          Trade Now
        </Link>
      </div>
      <div className="col-12 col-md-12 col-lg-6 overflow-y-hidden">
        <IndcesTradingView />
      </div>
    </div>
  );
};

export default IndecesTrade;
