import "./whyus.css";
import fox from "../../assets/Forex.svg";
import arr from "../../assets/Arrow.svg";
import ind from "../../assets/Indices.svg";
import com from "../../assets/Commodities.svg";
import cry from "../../assets/Crypto.svg";
import stk from "../../assets/Stock.svg";
import enr from "../../assets/Energies.svg";

const WhyUs = () => {
  return (
    <div className="whyUs">
      <div className="whyushead">
        <b className="whyUs1">Why Trade with Us?
        </b>
        <div className="whyUsTitle1">
          <div className="whyChooseTrading1">Why Choose KlayFx ?
          </div>
          <div className="lykosTradingIsContainer">
            <p className="opportunitiesToClients">
            KlayFx is here to help you with a variety of trading services and investment opportunities with advanced trading tools and solutions.
            </p>
            <p className="opportunitiesToClients">Explore our trading options and find what excites you today.</p>
          </div>
          <div className="lykosTradingIsContainer">
            Search our instruments, check what interest you today.
          </div>
        </div>
      </div>
      <div className="whyuscards">
        <div className="cardsrow1 row gy-4">
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="forexIcon1" alt="" src={fox} />
                <button className="arrow">
                  <img className="arrowIcon6" alt="" src={arr} />
                </button>
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Forex</div>
                <div className="eurusdUsdjpyGbpusd">
                EUR/USD, USD/JPY, and USD/CHF etc.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={ind} />
                <button className="arrow">
                  <img className="arrowIcon6" alt="" src={arr} />
                </button>
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Indices</div>
                <div className="eurusdUsdjpyGbpusd">{`S&P 500, FTSE 100, EUR 750, DJIA etc.`}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="forexIcon1" alt="" src={com} />
                <button className="arrow">
                  <img className="arrowIcon6" alt="" src={arr} />
                </button>
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Commodities</div>
                <div className="eurusdUsdjpyGbpusd">
                Gold, silver, copper, WTI, etc
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={cry} />
                <button className="arrow">
                  <img className="arrowIcon6" alt="" src={arr} />
                </button>
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Crypto</div>
                <div className="eurusdUsdjpyGbpusd">
                BTC/USD, LTC/USD, ETH/USD, etc.

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={stk} />
                <button className="arrow">
                  <img className="arrowIcon6" alt="" src={arr} />
                </button>
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Stock</div>
                <div className="eurusdUsdjpyGbpusd">
                Apple, amazon, twitter, facebook, etc.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={enr} />
                <button className="arrow">
                  <img className="arrowIcon6" alt="" src={arr} />
                </button>
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Energies</div>
                <div className="eurusdUsdjpyGbpusd">
                Oil, gas, and all energies

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
