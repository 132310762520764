import React from 'react'
import IndicesHero from '../components/IndecesHero/IndecesHero'
import FAQ from '../components/FAQ/FAQ'
import CTA from '../components/CTA/Cta'
import IndecesFeatures from '../components/IndecesFeatures/IndecesFeatures'
import IndecesTrade from '../components/IndecesTrade/IndecesTrade'
import Portability from '../components/Potability/Portability'
import { faqQuestionsIndices } from '../constants/faq'

const IndecesPage = () => {
  return (
    <>
    <IndicesHero/>
    <IndecesFeatures />
    <IndecesTrade />
    <Portability />

   
    <FAQ data={faqQuestionsIndices} />
    <CTA/>
    </>
  )
}

export default IndecesPage
