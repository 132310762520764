import React from "react";
import "./blog.css";
import { useStickyBox } from "react-sticky-box";
const BlogDetails = () => {
  const stickyRef = useStickyBox({ offsetTop: 30, offsetBottom: 40 });
  return (
    <div className="bl-container">
      <header className="bl-header">
        <p className="bl-subheading">Blog</p>
        <h1 className="bl-heading">
          Top 8 Essential Trading Tools for Successful Traders
        </h1>
      </header>
      <section className="bl-content">
        <img
          src="https://www.yamarkets.com/img/blog/top-8-essential-trading-tools-for-successful-traders.png"
          alt="large-image"
          className="poster-image"
        />
        <br />
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore cum
          aperiam ex, recusandae non qui tempore. Quisquam, cupiditate! In
          suscipit tenetur sit beatae inventore aliquid fugit expedita quis
          totam. Pariatur?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore cum
          aperiam ex, recusandae non qui tempore. Quisquam, cupiditate! In
          suscipit tenetur sit beatae inventore aliquid fugit expedita quis
          totam. Pariatur? Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Dolore cum aperiam ex, recusandae non qui tempore. Quisquam,
          cupiditate! In suscipit tenetur sit beatae inventore aliquid fugit
          expedita quis totam. Pariatur? Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Dolore cum aperiam ex, recusandae non qui tempore.
          Quisquam, cupiditate! In suscipit tenetur sit beatae inventore aliquid
          fugit expedita quis totam. Pariatur?
        </p>
        <p>
          Velit scelerisque in dictum non consectetur. Potenti nullam ac tortor
          vitae purus faucibus ornare suspendisse sed. Interdum velit euismod in
          pellentesque massa. Amet porttitor eget dolor morbi non. Pellentesque
          diam volutpat commodo sed egestas egestas. Massa sapien faucibus et
          molestie ac. Rhoncus aenean vel elit scelerisque mauris. In est ante
          in nibh mauris cursus mattis molestie a. At imperdiet dui accumsan sit
          amet nulla. Egestas erat imperdiet sed euismod nisi. In pellentesque
          massa placerat duis ultricies lacus sed turpis tincidunt. Maecenas
          volutpat blandit aliquam etiam. Orci phasellus egestas tellus rutrum
          tellus pellentesque. Id ornare arcu odio ut sem nulla pharetra diam
          sit. Faucibus pulvinar elementum integer enim. At risus viverra
          adipiscing at in tellus. Eget mauris pharetra et ultrices. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a. Diam quam nulla
          porttitor massa id neque aliquam. Pharetra convallis posuere morbi leo
          urna molestie. Venenatis cras sed felis eget velit aliquet. In nulla
          posuere sollicitudin aliquam ultrices sagittis. Eget nulla facilisi
          etiam dignissim diam. Commodo sed egestas egestas fringilla phasellus.
          Etiam erat velit scelerisque in dictum non consectetur a erat. Pretium
          aenean pharetra magna ac. Mattis nunc sed blandit libero. Quam id leo
          in vitae turpis massa sed elementum. Sem viverra aliquet eget sit
          amet. Neque aliquam vestibulum morbi blandit cursus risus at ultrices
          mi. Felis donec et odio pellentesque diam. Convallis posuere morbi leo
          urna molestie at elementum eu. Tincidunt ornare massa eget egestas
          purus viverra. Id faucibus nisl tincidunt eget. Quisque id diam vel
          quam elementum pulvinar. Aliquam purus sit amet luctus venenatis
          lectus magna. Diam donec adipiscing tristique risus.
        </p>
      </section>
      <aside className="bl-aside" ref={stickyRef}>
        <h4 className="bl-heading">Recent Post</h4>
        <div className="bl-card">
          <img
            src="https://www.yamarkets.com/img/blog/is-this-the-right-time-to-invest-in-gold.png"
            alt=""
          />
          <div>
            <p className="bl-heading bl-title">
              Is this the right time to invest in Gold?
            </p>
            <p className="author"> POSTED March 28, 2023 </p>
          </div>
        </div>
        <hr />
        <div className="bl-card">
          <img
            src="https://www.yamarkets.com/img/blog/is-this-the-right-time-to-invest-in-gold.png"
            alt=""
          />
          <div>
            <p className="bl-heading bl-title">
              Is this the right time to invest in Gold?
            </p>
            <p className="author"> POSTED March 28, 2023 </p>
          </div>
        </div>
        <hr />
        <div className="bl-card">
          <img
            src="https://www.yamarkets.com/img/blog/is-this-the-right-time-to-invest-in-gold.png"
            alt=""
          />
          <div>
            <p className="bl-heading bl-title">
              Is this the right time to invest in Gold?
            </p>
            <p className="author"> POSTED March 28, 2023 </p>
          </div>
        </div>
        <hr />
        <div className="bl-card">
          <img
            src="https://www.yamarkets.com/img/blog/is-this-the-right-time-to-invest-in-gold.png"
            alt=""
          />
          <div>
            <p className="bl-heading bl-title">
              Is this the right time to invest in Gold?
            </p>
            <p className="author"> POSTED March 28, 2023 </p>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default BlogDetails;
