import React from 'react'
import FAQ from '../components/FAQ/FAQ'
import MT4 from '../components/Mt4/MetaT'
import MTHero from '../components/MtHero/MtHero'
import MtFeatures from '../components/MtFeatures/MtFeatures'
import { faqQuestionsMetatrader } from '../constants/faq'

const MetaTradePage = () => {
  return (
    <>
    <MTHero />
     <MT4/>
     <MtFeatures />
       <FAQ data={faqQuestionsMetatrader} />
    </>
  )
}

export default MetaTradePage
