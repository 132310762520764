import React from 'react'
import DynamicPricing from '../components/Dynamic Pricing/DynamicPricing'
import FAQ from '../components/FAQ/FAQ'
import ComparisonATP from '../components/ATP Comparision/ATPcomparison'
import {faqQuestionsAccountType}  from '../constants/faq'
const AccountPricingPage = () => {
  return (
    <>
      <DynamicPricing />
      <ComparisonATP />
      <FAQ data={faqQuestionsAccountType} />
    </>
  )
}

export default AccountPricingPage
