import './enhacment.css';
import settings from '../../assets/settings 2.svg'
import help from '../../assets/helping-hand 1.svg'
import hand from '../../assets/help-desk 1.svg'
import high from '../../assets/High liquidity.svg'

const Enhancement = () => {
  	
  	return (
        <div className=''>
    		<div className='enhancement '>
      			<div className='enttitle'>
        				<b className='enhancement1'>Trading Insights
						</b>
        				<div className='howToEnhanceContainer'>
          					<p className='atLykosTrading'>How to Improve Your Trading at KlayFx 
							  </p>
        				</div>
      			</div>
      			<div className='enhacementcards '>
        				<div className='encards '>
          					<div className='encard'>
            						<img className='settings2Icon1' alt="" src={settings} />
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Use Advanced Charts</div>
              							<div className='ownSharesInContainer'>Track market trends with our easy-to-customize charts.
										  </div>
            						</div>
          					</div>
          					<div className='encard'>
            						<img className='highLiquidityIcon1' alt="" src={high}/>
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Apply Real-Time Indicators</div>
              							<div className='ownSharesInContainer'>
                								<p className='atLykosTrading'>Get up-to-date signals to make quick and informed decisions.
												</p>
                							
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className='encards '>
          					<div className='encard'>
            						<img className='settings2Icon1' alt="" src={hand} />
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Follow Economic News
										  </div>
              							<div className='ownSharesInContainer'>Stay informed with news updates that impact the market.
										  </div>
            						</div>
          					</div>
          					<div className='encard'>
            						<img className='settings2Icon1' alt="" src={help} />
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Learn from Our Resources
										  </div>
              							<div className='ownSharesInContainer'>Use our guides and tutorials to improve your trading skills.
										  </div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
            </div>);
};

export default Enhancement;
