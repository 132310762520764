
import './MtFeatures.css';
import acc from '../../assets/accept 1.svg'
const MtFeatures = () => {

	return (
		<div className='mtfeatures row	'>
			<div className='mtfeatureshead'>
				<b className='featuresmt'>FEATURES</b>
				<div className='mtfeaturesubtitle'>
					<div className='featuresAtYour1'>Powerful Trading Tools at Your Convenience
					</div>
					<div className='yourWorkSyncs1'>Seamlessly sync your work across web, phone, and desktop apps. Plan, share ideas, and update your team from any device.
					</div>

				</div>


			</div>
			<div className='row'>
				<div className='ftiles col-lg-6'>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Access to Multiple Markets: KlayFX Platform lets you trade a wide array of financial instruments across different markets.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Advanced Charting Tools: Use KlayFX Platform's charting features to analyze market trends with various indicators and graphical tools.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Automated Trading with EAs: Set up Expert Advisors (EAs) to automate trading based on predefined algorithms and rules.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Reliable Platform: Trade confidently with KlayFX Platform, known for its stability and effectiveness in executing trades.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Maximize your potential with klayfx platform customizable interface and expert advisors."</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>KlayFX Platform supports automated trading with the use of Expert Advisors (EAs) or forex robots. EAs can execute trades automatically based on predetermined trading rules.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Widely Adopted: Join millions of traders globally who use KlayFX Platform for its robust trading capabilities.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Customizable Interface: Adjust KlayFX Platform’s layout and settings to match your trading preferences and workflow.
						</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Execution of Trades: Expert Advisors (EAs) or forex robots can automatically execute trades according to your set criteria.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Backtesting Strategies: Use the strategy tester in KlayFX Platform to evaluate your trading strategies against historical data.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Mobile Trading: Manage and trade your accounts using the KlayFX Platform mobile app available for both iOS and Android.
						</div>
					</div>
				</div>
				<div className='ftiles col-lg-6'>
			
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Enhanced Security: KlayFX Platform provides high-level encryption and security to protect your trading account and personal information.
						</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Technical Analysis Tools: KlayFX Platform offers a suite of tools for technical analysis, including a range of indicators and drawing tools.
						</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Automated Trading Systems: Configure EAs to handle trading operations automatically, allowing you to trade 24/7.
						</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>KlayFX Platform supports automated trading with the use of Expert Advisors (EAs) or forex robots. EAs can execute trades automatically based on predetermined trading rules.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Automated Trading Systems: Configure EAs to handle trading operations automatically, allowing you to trade 24/7.
						</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Trusted Platform: Rely on KlayFX Platform for consistent performance and reliability in trade execution</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Global Trading Network: Engage with a large, active community of traders using the KlayFX Platform platform.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Personalized Setup: Customize the KlayFX Platform interface to fit your individual trading strategy and preferences.</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Strategy Backtesting: Assess the effectiveness of your trading strategies by running tests with historical price data.
						</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>On-the-Go Trading: Access and manage your trading activities seamlessly via the KlayFX Platform mobile application.
						</div>
					</div>
					<div className='ftile'>
						<img className='accept1Icon9' alt="" src={acc} />
						<div className='mt4SupportsAutomated'>Secure Trading Environment: KlayFX Platform ensures secure transactions and data protection, offering peace of mind for your trading activities.
						</div>
					</div>
				</div>
			</div>
		</div>);
};

export default MtFeatures;
