import './portability.css'
import  Mobile from '../../assets/PortabilityImage.png'
import { Link } from 'react-router-dom';
const Portability = () => {
  	
  	return (
    		<div className='portability row'>
      			<img className='portabilityimageIcon  col-12 col-md-12 col-lg-6' alt="" src={Mobile}/>
      			<div className='portabilitycontent  col-12 col-md-12 col-lg-6'>
        				<div className='portabilyhead'>
          					<div className='portabilysubtitle'>
            						{/* <b className='portability1'>Portability</b> */}
            						<div className='powerfulTradingWherever1'>Trade Anytime, Anywhere</div>
          					</div>
          					<div className='tradeLikeYoure1'>Experience powerful trading on the go with our award-winning mobile app. Manage trades, track positions, set alerts, and view optimized charts, all from your smartphone.
							  </div>
        				</div>
        				<Link className='getStartButton1'  to='#'>
                                  Get started
                            </Link>
      			</div>
    		</div>);
};

export default Portability;
