import React from 'react'
import PrivacyPolicy from '../components/Privacy Policy/PrivacyPolicy'

const PrivacyPolicyPage = () => {
  return (
    <>
      <PrivacyPolicy/>
    </>
  )
}

export default PrivacyPolicyPage
