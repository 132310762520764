import React, { useEffect } from "react";
import "./blog.css";
import bl1 from "../../assets/learn-the-basics-of-forex-liquidity.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogApi } from "../../actions/BlogAction";
import Loader from "../Loader/Loader";

const BlogsCard = () => {
  const { loading, blogdata } = useSelector((state) => state.blog);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogApi());
  }, [dispatch]);

  const blogData = [
    {
      id: 1,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 2,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 3,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 4,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 5,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 6,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 7,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 8,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 9,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 10,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 11,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 12,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 13,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 14,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 15,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 16,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    {
      id: 17,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
    ,
    {
      id: 18,
      imageSrc: bl1,
      title: "Why is the Tesla Cybertruck designed the way it is?",
      description: "An exploration into the truck's polarizing design",
      date: "23 Dec 2019",
    },
  ];

  return (
    <div className="dynamicPricing">
      <div className="dynamicPricingText1">
        <b className="accountTypes">Blogs</b>
        <div className="accountTypes1">Our Latest Blogs</div>
      </div>
      <div className="blog-container">
        {blogData.map((blog) => (
          <div className="blog-card" key={blog.id}>
            <div className="blog-card-header">
              <img src={blog.imageSrc} alt="Blog" />
            </div>
            <div className="blog-card-body">
              <h4>{blog.title}</h4>
              <p>{blog.description}</p>
              <div className="blog-user">
                <small>{blog.date}</small>
                <Link
                  to="/blog-details"
                  className="getStartButton1 readmorestartedbutton">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogsCard;
