import './cryptoHero.css'
import crypto from '../../assets/CryptoHroImage.png'
const CryptoHero = () => {
  	
  	return (
    		<div className='cryptohero'>
      			<div className='cryptocontent'>
        				<div className='cryptosubtitle'>
          					<div className='cryptoheading'>
            						<div className='cryptohr'>
              							<b className='cryptocurrency'>Cryptocurrency</b>
              							<div className='getMoreCryptoContainer'>
                								<p className='yourCash'>Trade Bitcoin More Easily</p>
              							</div>
            						</div>
            						<div className='buySell1'>{`Enjoy seamless trading of Bitcoin and other top cryptocurrencies.`}</div>
          					</div>
        				</div>
                        <button className="getStartButton1">Get started</button>

      			</div>
      			<img className='cryptohroimageIcon' alt="" src={crypto} />
    		</div>);
};

export default CryptoHero;
