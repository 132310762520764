import "./protection.css";
// import proimg from "../../assets/PImg.png";
import ProtectionTradingView from "../ProtectionTradingView";

const Protection = () => {
  return (
    <div className="protection row ">
      <div className="protectionSidebar1 col-12 col-md-12 col-lg-6 ">
        <div className="headingpro">
          <b className="protection1">Technical Analysis
          </b>
          <div className="procontent">
            <div className="stocksAndEtfsContainer">
              <p className="stocksAndEtfs">
                {" "}
                Analyze Like a Pro

              </p>
            </div>
            <div className="lykosTrading241">
            Effortlessly perform detailed technical analysis using our advanced charting tools, customizable indicators, and real-time data feeds to make informed trading decisions.
            </div>
          </div>
        </div>
      </div>
      <div className="proimage  col-12 col-md-12 col-lg-6 overflow-y-hidden">
        {/* <img src={proimg} /> */}
        <ProtectionTradingView />
      </div>
    </div>
  );
};

export default Protection;
