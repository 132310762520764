import axios from "axios";

// Utility function to handle POST requests for contact form
export const sendContactForm = async (formData) => {
  const formDataToSend = new FormData();

  // Loop through the formData object and append each key-value pair
  Object.keys(formData).forEach((key) => {
    formDataToSend.append(key, formData[key]);
  });

  try {
    const response = await axios.post(
      "https://klayfx.com/admin/api/contact_us_api/contact_us/",
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const submitSignupForm = async (formData) => {
  console.log(formData);
  const formDataToSend = new FormData();

  // Append the fields to FormData
  formDataToSend.append("name", formData.name);
  formDataToSend.append("email", formData.email);
  formDataToSend.append("u_address", formData.u_address);
  formDataToSend.append("mobile", formData.mobile);

  try {
    const response = await axios.post(
      "https://klayfx.com/admin/api/contact_us_api/contact_reg/", // Replace with actual API URL
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data", // This is crucial for FormData
        },
      }
    );

    console.log("response", response);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};