import ForexCarosel from "../ForexCarosel/ForexCarosel";

import ff from "../../assets/Sign up fasr.svg";
import sm from "../../assets/Start small.svg";
import fav from "../../assets/Find your favourites.svg";
import TradingViews from "../tradingViews";
const Cryptodiscover = () => {
  return (
    <div className="discoverForex row">
      <div className="discoverSidebar col-lg-6 col-md-12">
        <div className="discoverTitle">
          <div className="textdd">
            <div className="discoverForexParent">
              <b className="discoverForex1">Explore Cryptocurrencies</b>
              <div className="tradeTheMost1">
              Trade Top Cryptos Effortlessly
              </div>
            </div>
            <div className="tradeAudchfEurchf">
              Start your first trade with these easy steps.
            </div>
          </div>
        </div>
        <div className="sidebarContent1">
          <div className="startSmall">
            <img className="signUpFasr1" alt="" src={ff} />
            <div className="textd2">
              <div className="signUpFast3">Quick Sign-Up</div>
              <div className="tradeAudchfEurchf">
              Get started quickly—almost as fast as ordering takeout.
              </div>
            </div>
          </div>
          <div className="sidebarContentChild" />
          <div className="startSmall">
            <img className="signUpFasr1" alt="" src={sm} />
            <div className="textd2">
              <div className="signUpFast3">Start with a Small Investment</div>
              <div className="tradeAudchfEurchf">
              Trade popular cryptocurrencies like Bitcoin, Ethereum, and more.
              </div>
            </div>
          </div>
          <div className="sidebarContentChild" />
          <div className="startSmall">
            <img className="signUpFasr1" alt="" src={fav} />
            <div className="textd2">
              <div className="signUpFast3">Choose Your Favorites
              </div>
              <div className="tradeAudchfEurchf">
              Trade popular cryptocurrencies like Bitcoin, Ethereum, and more.
              </div>
            </div>
          </div>
        </div>
        <button className="getStartButton1">Get started</button>
      </div>
      <div className="curoselwraper col-lg-6  col-md-12 overflow-y-hidden">
        <TradingViews />
      </div>
    </div>
  );
};

export default Cryptodiscover;
