import "./contact.css";
import add from "../../assets/Address.svg";
import call from "../../assets/Call us.svg";
import email from "../../assets/Email us.svg";
const ContactUs = () => {
  return (
    <div className="contactUs">
      <div className="contacthead">
        <b className="contactUs1">Contact us</b>
        <div className="contactsubtitle">
          <div className="wereHereTo1">
            We’re here to support you, anytime and in any way you need.
          </div>
          <div className="con">
            Whatever financial questions you have, we’re here to assist you.
          </div>
        </div>
      </div>
      <div className="contactcards">
        {/* <div className="card">
          <img className="callUsIcon1" alt="" src={call} />
          <div className="contacttitle">
            <div className="callUs">Call us</div>
            <div className="con">+971-00-000-1111</div>
          </div>
        </div> */}
        <div className="card">
          <img className="callUsIcon1" alt="" src={email} />
          <div className="contacttitle">
            <div className="callUs">Email us</div>
            <div className="con"><a href="mailto:contact@klayfx.com" className="txt-dark"> contact@klayfx.com</a></div>
          </div>
        </div>
        <div className="card">
          <img className="callUsIcon1" alt="" src={add} />
          <div className="contacttitle">
            <div className="callUs">Address 1</div>
            <div className="con">
              <p className="room220322f299qrc">
                2 Ullswater Avenue, Ashton- Under-Lyne, Greater Manchester, OL7 9EU

              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <img className="callUsIcon1" alt="" src={add} />
          <div className="contacttitle">
            <div className="callUs">Address 2</div>
            <div className="con">
              <p className="room220322f299qrc">
                Ullswater Avenue, Ashton-Under-Lyne
                Greater Manchester
                United Kingdom Ol7 9eu


              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
