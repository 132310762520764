import "./abouthero.css";
const AboutHero = () => {
  return (
    <div className="abouthero">
      <div className="aboutheading">
        <b className="aboutUs">About us</b>
        <div className="aboutLykosGlobal1">
        About KlayFX 
        </div>
      </div>
      <div className="lykosGlobalTrading1">
      Welcome to KlayFX , where your financial success is our priority. We are a dedicated trading firm committed to providing exceptional trading services and investment opportunities. Our platform combines advanced technology with a user-friendly experience to support traders of all levels. From comprehensive analysis tools to real-time data, we equip you with everything you need to make informed decisions and achieve your financial goals. At KlayFX, we are more than just a trading platform—we are your partner in finding success in the financial markets with confidence and ease.
      </div>
    </div>
  );
};

export default AboutHero;
