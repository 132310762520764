import React from 'react'
import FAQ from '../components/FAQ/FAQ'
import AccountFundingHero from '../components/FundingHero/FundingHero'
import FundingPaymentOptions from '../components/FundingOptions/FundingOption'
import {faqQuestionsAccountFunding}  from '../constants/faq'
const AccountFundingPage = () => {
  return (
    <>
    <AccountFundingHero/>
    <FundingPaymentOptions />
      <FAQ data={faqQuestionsAccountFunding}/>
    </>
  )
}

export default AccountFundingPage
