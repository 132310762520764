import { Link } from 'react-router-dom';
import './cta.css'
const CTA = () => {
  	
  	return (
    		<div className='cta col-lg-12'>
      			
      			<div className='signUpAnd1'>Register Now to Access Premium Trading Tools at No Extra Cost
				  </div>
      			<div className='buttonsCat'>
       
        				<Link className='openLive'  to='https://my.klayfx.com/register'>
          					<div className=''>Open live account</div>
        				</Link>
      			</div>
             
    		</div>);
};

export default CTA;
