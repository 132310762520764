import "./MtHero.css";
import Mt from "../../assets/MOCKUP-01.png";

import { Link } from "react-router-dom";
const MTHero = () => {
  return (
    <div className="mthero">
      <div className="mt">
        <div className="mtsubtitle">
          <div className="mthead">
            <b className="metatrader400">KlayFX Platform</b>
            <div className="tradeOnWorldContainer">
              <p className="mt4Platforms">Trade Smarter with Advanced KlayFX  Platform</p>
            </div>
          </div>
          <div className="useThisApp1">
          Access advanced features and tools on our platform to enhance your trading experience with KlayFX .
          </div>
        </div>
        <div className="buttons">
         
          <Link
            className="openLive"
            to="https://my.klayfx.com/register">
            <div className="openDemoAccount1">Open live account</div>
          </Link>
        </div>
      </div>
      <img className="mtheroimageIcon" alt="" src={Mt} />
    </div>
  );
};

export default MTHero;
