import {
  GET_BLOG_FAILURE,
  GET_BLOG_REQUEST,
  GET_BLOG_SUCCESS,
} from "../constants/BlogConstants";

export const BlogReducer = (state = { blogdata: [] }, action) => {
  switch (action.type) {
    case GET_BLOG_REQUEST:
      return {
        loading: true,
        blogdata: [],
      };

    case GET_BLOG_SUCCESS:
      return {
        loading: false,
        blogdata: action.payload,
      };

    case GET_BLOG_FAILURE:
      return {
        loading: false,
        blogdata: action.payload,
      };

      default:
        return state;
  }
};
