import "./cfd.css";
import heroImage from "../../assets/crypto.png";
const CFDHero = () => {
  return (
    <div className="cfdhero row">
      <div className="cf col-12 col-md-12 col-lg-6">
        <div className="cfcontent">
          <div className="cfheading">
            <b className="cfd">CFDs Trading</b>
            <div className="whatAreCfds">Trade CFDs</div>
          </div>
          <div className="contractsForDifferenceContainer">
            <p className="blankLine">
            Trade with Contracts for Difference (CFDs) to capitalize on price movements of underlying assets like indices, shares, and commodities.

            </p>
            <p className="blankLine">&nbsp;</p>
            <p className="blankLine">
            With CFDs, you’re agreeing to exchange the difference in an asset’s value from when you open to when you close the contract. You don’t own the asset itself, but you can still profit from favorable price changes or face losses if prices move against you.
            </p>
            
          </div>
          <button className="getStartButton1">Get started</button>
        </div>
   
      </div>
      <div className="cfdimage col-12 col-md-12 col-lg-6">
        <img className="illustrationIcon" alt="" src={heroImage} />
      </div>
    </div>
  );
};

export default CFDHero;
