import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap"; // Import Alert from react-bootstrap
import styles from "./contactpage.css";
import trade from "../assets/trade-11.jpg";
import logo from "../assets/logo-white.png";
import { submitSignupForm } from "../utils";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    u_address: "",
  });

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (alertVisible) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [alertVisible]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await submitSignupForm(formData);
      if (response !== null) {
        setAlertMessage("Signup successful!");
        setAlertVariant("success");
      } else {
        setAlertMessage("Failed to submit the form.");
        setAlertVariant("danger");
      }
      setAlertVisible(true);
      // Clear form after submission
      setFormData({
        name: "",
        email: "",
        mobile: "",
        u_address: "",
      });
    } catch (error) {
      setAlertMessage("Failed to submit the form.");
      setAlertVariant("danger");
      setAlertVisible(true);
    }
  };

  return (
    <>
      <section className="contact-page-sec sign section-space sign-h-auto p-0 bg-theme-primary">
        <div className="bg-img">
          <div className="row w-100 m-0 px-0 h-100 justify-content-end position-relative z-1 bg-b">
            <div className="col-md-6 bg-theme-primary">
              <div className="signup-form h-100">
                <h1 className="welcome-back">Welcome Back!</h1>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="signup-form bg-theme-black h-100 mt-0">
                <img src={logo} className="mb-4" width={200} />
                <h3 className="text-white mb-4">Fill Out the Form Below Now</h3>

                {/* Display alert message */}
                {alertVisible && (
                  <Alert
                    variant={alertVariant}
                    onClose={() => setAlertVisible(false)}
                    dismissible
                  >
                    {alertMessage}
                  </Alert>
                )}

                <form onSubmit={handleSubmit} className="p-0">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <label>Enter Name</label>
                        <input
                          type="text"
                          placeholder="Your Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <label>Enter Email Address</label>
                        <input
                          type="email"
                          placeholder="Your E-mail"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <label>Enter Phone Number</label>
                        <input
                          type="text"
                          placeholder="Your Phone Number"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="single-input-field">
                        <label>Enter Location</label>
                        <input
                          type="text"
                          placeholder="Your Location"
                          name="u_address"
                          value={formData.u_address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="single-input-fieldsbtn">
                      <input
                        type="submit"
                        value="Submit"
                        className="submit-input btn34 btn btn-primary w-100"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
