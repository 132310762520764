
import  './whytrade.css';
import side from '../../assets/SidebarImage.png'
import hour from '../../assets/24 hour market.svg'
import wide from '../../assets/Wide range of markets.svg'
import profit from '../../assets/Profit potential.svg'
import ab from '../../assets/Accessibilty.svg'
import high from '../../assets/High liquidity.svg'
import lever from '../../assets/Leverage.svg'
import diver from '../../assets/Diversification.svg' 
const WhyTradeForex = () => {
  	
  	return (
    		<div className='whyTradeForex2 row'>
      			<div className='col-md-12 col-lg-6 whytradesidebar '>
        				<div className='tradeheadingSide'>
          					<b className='why'>Trade Forex</b>
            						<div className='whyTradeForexParent'>
              							<div className='whyTradeForex3'>Why Trade Forex?</div>
                								<div className='someOfThe1'>Why you should trade in forex:
												</div>
                								</div>
                								</div>
                								<img className='sidebarimageIcon' alt="" src={side} />
                								<div className='sidebarcards'>
                  									<div className='sidecard'>
                    										<img className='hourMarketIcon1' alt="" src={hour} />
                    										<div className='cardheading'>
                      											<div className='hourMarket'>24/5 Trading Hours</div>
                      											<div className='someOfThe1'>The forex market is open 24 hours a day, five days a week, giving you the flexibility to trade at any time that fits your schedule</div>
                    										</div>
                  									</div>
                  									<div className='sidecard'>
                    										<img className='hourMarketIcon1' alt="" src={wide} />
                    										<div className='cardheading'>
                      											<div className='hourMarket'>Variety of Opportunities</div>
                      											<div className='someOfThe1'>With numerous currency pairs and trading hours, forex trading provides a broad range of trading options.
																  </div>
                    										</div>
                  									</div>
                								</div>
                								</div>
                								<div className='col-md-12 col-lg-5 rightbar'>
                  									<div className='sidecard'>
                    										<img className='hourMarketIcon1' alt="" src={profit} />
                    										<div className='cardheading'>
                      											<div className='hourMarket'>Profit potential</div>
                      											<div className='someOfThe1'>Forex trading offers the chance to earn significant returns by buying and selling currencies if you correctly predict market movements.</div>
                    										</div>
                  									</div>
                  									<div className='sidecard'>
                    										<img className='hourMarketIcon1' alt="" src={ab} />
                    										<div className='cardheading'>
                      											<div className='hourMarket'>Easy Access
																  </div>
                      											<div className='someOfThe1'>Trade forex with just an internet connection and a trading account. There are many brokers available, offering various choices for getting started.</div>
                    										</div>
                  									</div>
                  									<div className='sidecard'>
                    										<img className='hourMarketIcon1' alt="" src={high} />
                    										<div className='cardheading'>
                      											<div className='hourMarket'>Efficient Market</div>
                      											<div className='someOfThe1'>The high liquidity in the forex market means you can buy and sell currencies quickly and at favorable prices without impacting the market.</div>
                    										</div>
                  									</div>
                  									<div className='sidecard'>
                    										<img className='hourMarketIcon1' alt="" src={lever} />
                    										<div className='cardheading'>
                      											<div className='hourMarket'>Leverage Options</div>
                      											<div className='someOfThe1'>Forex trading lets you use leverage to control larger positions with a smaller amount of capital. This can increase potential profits but also comes with higher risk.
																  </div>
                    										</div>
                  									</div>
                  									<div className='sidecard'>
                    										<img className='hourMarketIcon1' alt="" src={diver} />
                    										<div className='cardheading'>
                      											<div className='hourMarket'>Diversify Your Portfolio
																  </div>
                      											<div className='someOfThe1'>Incorporating forex trading into your investment strategy can help spread risk and enhance overall portfolio stability.
																  </div>
                    										</div>
                  									</div>
                								</div>
                								</div>);
              							};
              							
              							export default WhyTradeForex;
              							