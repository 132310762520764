import React from "react";
import FAQ from "../components/FAQ/FAQ";
import CTA from "../components/CTA/Cta";
import WhyUs from "../components/WhyUS/Whyus";
import CFDHero from "../components/CFDHero/CfdHero";
import CfdEnhancement from "../components/CfdEnhacment/CfdEnhacment";
import CfdPricing from "../components/CFDPricing/Cfdpricing";
import { faqQuestionsCfcs }  from '../constants/faq'

const Cfcs = () => {
  return (
    <>
    <CFDHero/>
    <CfdEnhancement />
     <CfdPricing/>
      <WhyUs />
      <FAQ data={faqQuestionsCfcs} />
      <CTA />
    </>
  );
};

export default Cfcs;
