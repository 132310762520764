import './FundingOption.css'
import paypal from '../../assets/Option Image.png'
import visa from '../../assets/Frame 1000002068 (1).png'
import earth from '../../assets/Frame 1000002068.png'
const FundingPaymentOptions = () => {
  	
  	return (
    		<div className='fundingPaymentOptions1'>
      			<div className='paymentFundingHeading1'>
        				<b className='options'>Options</b>
        				<div className='paymentOptions'>Payment Options</div>
      			</div>
      			<div className='fundingOptions'>

                    <div className='optionContainer'>
                        <div className='halfOption'>
                        <img className='optionimg' alt="" src={paypal} />
                        <span>Paypal</span>
                        </div>
                        <div className='restContent'>
                            <p>*Upload Screenshot in the CRM</p>
                            <button className='getStartButton1'>
                            Pay now
                            </button>

                        </div>
                    </div>
                    <div className='optionContainer'>
                        <div className='halfOption'>
                        <img className='optionimg' alt="" src={earth} />
                        <span>Bank wire</span>
                        </div>
                        <div className='restContent'>
                            <p>*Upload Screenshot in the CRM</p>
                            <button className='getStartButton1'>
                            Pay now
                            </button>

                        </div>
                    </div>
                    <div className='optionContainer'>
                        <div className='halfOption'>
                        <img className='optionimg' alt="" src={visa} />
                        <span>Credit card</span>
                        </div>
                        <div className='restContent'>
                            <p>*Upload Screenshot in the CRM</p>
                            <button className='getStartButton1'>
                            Pay now
                            </button>

                        </div>
                    </div>
        			
        			
      			</div>
    		</div>);
};

export default FundingPaymentOptions;
