import React from "react";
import FAQ from "../components/FAQ/FAQ";
import CTA from "../components/CTA/Cta";
import Cryptodiscover from "../components/Discover Crypto/Cryptodiscover";
import CryptoHero from "../components/Crypto Hero/cryptoHero";
import CryptoProtection from "../components/CryptoProtection/CryptoProtection";
import { faqQuestionsCrypto } from '../constants/faq'

const CryptoPage = () => {
  return (
    <>
      <CryptoHero />
      <Cryptodiscover />
      <CryptoProtection />
      <br />
      <br />
      <FAQ data={faqQuestionsCrypto}/>
      <CTA />
      
    </>
  );
};

export default CryptoPage;
