import './indeces.css'
import mock from '../../assets/HerokImage.png'
import { Link } from 'react-router-dom';
const IndicesHero = () => {
  	
  	return (
    		<div className='indiceshero row'>
      			<div className='indicescontent col-lg-6'>
        				<div className='indecesheadtitle'>
          					<div className='indecesheading'>
            						<b className='indices'>Indices</b>
            						<div className='indexTrading'>Indices Trading
									</div>
          					</div>
          					<div className='tradeOnOverContainer'>{`Trade over 80 global indices, including the FTSE 100, with leverage on our top-rated platform. Benefit from tight spreads, fast trade execution, and excellent customer support. `}
								
							</div>
          					</div>
                              <Link className='getStartButton1'  to='#'>
                                  Get started
                            </Link>
        				</div>
                        <div className='col-lg-6'>
        				<img className='herokimageIcon' alt="" src={mock} />
                        </div>
      			</div>);
      			};
      			
      			export default IndicesHero;
      			