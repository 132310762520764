import { Table } from "react-bootstrap";
import "./ATPCom.css";
const ComparisonATP = () => {
  return (
    <div className="comparisonAtp">
      <div className="comparisonAtpHeading">
        <b className="comparison">Comparison</b>
        <div className="comparePlansAnd1">Compare plans and features</div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Usage</th>
            {/* <th>Wolf</th> */}
            <th>STANDARD</th>
            <th>PRO</th>
            <th>ECN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Minimum deposit</td>          
            <td>$1</td>
            <td>$1000</td>
            <td>$2500</td>
          </tr>
          <tr>
            <td>Minimum trading volume</td>           
            <td>0.01</td>
            <td>0.01</td>
            <td>0.01</td>
          </tr>
          <tr>
            <td>Swap free account</td>
         
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td> Spread starts from</td>
         
            <td>1.8 pips</td>
            <td>1 pips</td>
            <td>Raw</td>
          </tr>
          <tr>
            <td> Commission</td>
        
            <td>No</td>
            <td>No</td>
            <td>No</td>
          </tr>
          <tr>
            <td> Leverage</td>
         
            <td>1:500</td>
            <td>1:1200</td>
            <td>1:1200</td>
          </tr>
          <tr>
            <td>Account currency types</td>
           
            <td>USD</td>
            <td>USD</td>
            <td>USD</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ComparisonATP;
