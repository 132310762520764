import "./aboutfaq.css";
import vol from "../../assets/Volume.svg";
import sup from "../../assets/5 support.png";
import trans from "../../assets/LMAXpng-01.png";
import { Row, Col } from "react-bootstrap";

const AbouFaQ = () => {
  return (
    <div className="aboufaq">
      <div className="faqheading">
        <b className="faqs">Financial Market</b>
        <div className="gotMoreQuestions1">
        Helping you find success in the biggest financial market in the world
        </div>
      </div>
      <div className="showcards">
        <Row className="gy-4 justify-content-center">
          <Col lg={6} xxl={4}>
            <div className="card33">
              <div className="volumeParent">
                <img className="volumeIcon" alt="" src={vol} />
                <div className="billion">
                  <p className="p">{`$65 `}</p>
                  <p className="p">Billion</p>
                </div>
              </div>
              <div className="averageDailyVolume1">Average daily volume</div>
            </div>
          </Col>
          <Col lg={6} xxl={4}>
            <div className="card33">
              <div className="volumeParent">
                <img className="transactionsIcon" alt="" src={trans} />
                <div className="billion">
                  <p className="p">Liquidity </p>
                
                </div>
              </div>
              <div className="averageDailyVolume1">
              Direct Liquidity Streams
              </div>
            </div>
          </Col>
          <Col lg={6} xxl={4}>
            <div className="card33">
              <div className="volumeParent">
                <img className="transactionsIcon" alt="" src={sup} />
                <div className="billion">
                  <p className="p">24/5</p>
                  <p className="p">Support</p>
                </div>
              </div>
              <div className="customerSupportIn1">
                Customer Support in 40 languages
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AbouFaQ;
