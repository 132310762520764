export const faqQuestionsIndices = [
  {
    id: 1,
    question: "What indices can I trade with KlayFX?",
    answer:"You can trade over 80 indices, including major global indices like the FTSE 100, as well as regional indices from Australia, Asia-Pacific, the US, and Europe.",
  },
  {
    id: 2,
    question: "What are cash and forward indices?",
    answer:
      "Cash indices are traded for immediate settlement, while forward indices are contracts to trade at a future date, allowing for flexibility in your trading strategies.",
  },
  {
    id: 3,
    question: "Can I trade indices outside regular market hours?",
    answer:
      "Yes, many of our indices, including popular ones like the UK 100 and US 30, are available for trading 24/5, so you can trade even when the markets are closed.",
  },
  {
    id: 4,
    question: "How can I trade on mobile?",
    answer:
      "Download our award-winning mobile trading app to manage trades, track positions, set alerts, and analyze charts directly from your smartphone.",
  },
];


export const faqQuestionsForex = [
  {
    id: 1,
    question: "How do I start trading forex?",
    answer:"To start trading, open an account with us, fund your account, and use our platform to buy and sell currency pairs.",
  },
  {
    id: 2,
    question: "What are currency pairs?",
    answer:
      "Currency pairs are combinations of two currencies, like EUR/USD (Euro/US Dollar). You trade one currency for another, hoping to profit from changes in their values.",
  },
  {
    id: 3,
    question: "What is leverage in forex trading?",
    answer:
      "Leverage allows you to control a large position with a smaller amount of money. It can increase your potential profits but also your risk of losses.",
  },
  {
    id: 4,
    question: "Can I trade forex with just a smartphone?",
    answer:
      "Yes, KlayFX offers mobile trading that lets you trade forex directly from your smartphone, allowing you to manage your trades on the go.",
  },
];

export const faqQuestionsMetatrader = [
  {
    id: 1,
    question: "What is KlayFX   platform?",
    answer:"KlayFX   platform is a popular trading platform that provides advanced charting tools, technical indicators, and automated trading capabilities to help you analyze and trade financial markets.",
  },
  {
    id: 2,
    question: "How can I use Expert Advisors (EAs) on KlayFX Platform?",
    answer:
      "You can use Expert Advisors (EAs) on KlayFX Platform to automate your trading. EAs are scripts that execute trades based on your set criteria, allowing you to trade automatically without manual intervention.",
  },
  {
    id: 3,
    question: "Can I trade on  KlayFX Platform? using my mobile device?",
    answer:
      "Yes,  KlayFX Platform is available as a mobile app for both iOS and Android devices, enabling you to manage and execute trades from anywhere at any time.",
  },
  {
    id: 4,
    question: "How secure is trading on  KlayFX??",
    answer:
      " KlayFX Platform provides high-level security with encryption to protect your trading account and personal information, ensuring a secure trading environment.",
  },
];

export const faqQuestionsCfcs = [
  {
    id: 1,
    question: "What are CFDs?",
    answer:"Contracts for Difference (CFDs) are financial derivatives that allow you to trade based on the price movements of assets like stocks, indices, forex, commodities, and more, without owning the actual asset.",
  },
  {
    id: 2,
    question: "What types of CFDs can I trade with KlayFX?",
    answer:
      "At KlayFX, you can trade a wide range of CFDs, including individual stocks, indices, forex pairs, commodities, options, and bonds.",
  },
  {
    id: 3,
    question: "How is CFD pricing determined?",
    answer:
      "Our CFD pricing is based on minimum spreads, which can vary depending on your account tier and country of residence. For exact pricing details, refer to the trade tickets on our platform.",
  },
  {
    id: 4,
    question: "What support do I receive when trading CFDs?",
    answer:
      "KlayFX offers award-winning support with features like integrated trade signals, news feeds, and advanced risk management tools to help you make informed trading decisions.",
  },
];
export const faqQuestionsCrypto = [
  {
    id: 1,
    question: "What cryptocurrencies can I trade with KlayFX?",
    answer:"You can trade a variety of popular cryptocurrencies, including Bitcoin, Ethereum, and all other major & minor coins.",
  },
  {
    id: 2,
    question: "How do I start trading cryptocurrencies?",
    answer:
      "Simply sign up for an account, deposit funds, and you can start trading cryptocurrencies with as little as $100.",
  },
  {
    id: 3,
    question: "How is my cryptocurrency investment protected?",
    answer:
      "We use industry-leading security measures, including cold storage for most coins and crime insurance against theft and cybersecurity breaches.",
  },
  {
    id: 4,
    question: "Can I trade cryptocurrencies on mobile?",
    answer:
      "Yes, you can trade cryptocurrencies easily on our mobile app, allowing you to manage your trades and track your investments from anywhere.",
  },
];
export const faqQuestionsAccountType = [
  {
    id: 1,
    question: "What is the minimum deposit for each account type?",
    answer:"The minimum deposit varies by account type, starting from $10 for the Wolf account to $50,000 for the Expert account.",
  },
  {
    id: 2,
    question: "How do spreads differ across account types?",
    answer:
      "Spreads range from 1.8 pips for the Wolf account to as low as 0.1 pips for the Best and Expert accounts.",
  },
  {
    id: 3,
    question: "Are swap-free accounts available?",
    answer:
      "Yes, all KlayFX accounts can be set up as swap-free to avoid overnight charges.",
  },
  {
    id: 4,
    question: "What leverage is offered?",
    answer:
      "Leverage ranges from 1:500 for the Wolf and Beginners accounts to 1:200 for the Best and Expert accounts.",
  },
];
export const faqQuestionsAccountFunding = [
  {
    id: 1,
    question: "What payment methods can I use to fund my account?",
    answer:"You can use bank transfers, e-wallets, or PayPal to deposit funds into your KlayFX account.",
  },
  {
    id: 2,
    question: "Are there any fees associated with deposits?",
    answer:
      "KlayFX does not charge fees for deposits, but your payment provider may have its own charges.",
  },
  {
    id: 3,
    question: "How long does it take for deposits to be processed?",
    answer:
      "Deposits typically process instantly for e-wallets and PayPal, while bank transfers may take a few business days.",
  },
  {
    id: 4,
    question: "Can I withdraw funds using the same method I used to deposit?",
    answer:
      "Yes, withdrawals are usually processed using the same method as your deposit for convenience.",
  },
];