import styles from "./story.css";
import comp from "../../assets/story image 1.png";
import comp2 from "../../assets/story image 2.png";
import comp3 from "../../assets/story image 3.png";
import comingsoon from "../../assets/bannervasd.png";
const Story = () => {
  return (
    <div className="story d-block">
      <div className="row">
        <div className="storyHeading col-lg-6  col-md-12 ">
           
          <div className="ourStories">
            <b className="story1">Our Story So Far </b>
            <div className="ourstoryTitle">
              <div className="ourStorySo1">Built a Top-Notch Platform</div>
              <div className="noMatterWhat1">
              We’ve developed a powerful and easy-to-use trading platform to make your trading experience smooth and effective.
              </div>
            </div>
          </div>
          <section className="py-5">
            <ul className="timeline">
              <li className="timeline-item mb-5">
                <h5 className="fw-bold">Grown Our Global Presence
                </h5>
                <p className="text-muted mb-2 fw-bold"></p>
                <p className="text-muted">
                We now offer access to a wide range of international markets, giving you more opportunities to trade and invest.
                </p>
              </li>

              <li className="timeline-item mb-5">
                <h5 className="fw-bold">Improved Support and Education
                </h5>
                {/* <p className="text-muted mb-2 fw-bold">19 March 2020</p> */}
                <p className="text-muted">
                We provide great customer service and helpful learning resources to support traders at every level.
                </p>
              </li>

              <li className="timeline-item mb-5">
                <h5 className="fw-bold">Added Key Trading Tools
                </h5>
                {/* <p className="text-muted mb-2 fw-bold">24 June 2020</p> */}
                <p className="text-muted">
                We’ve included features like high liquidity, flexible trading hours, and smart risk management tools to help you trade more efficiently.

                </p>
              </li>


            </ul>
          </section>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="storysidebar">
            <div className="storyImage31">
              <img
                alt=""
                src={comp3}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div className="storyImage21">
              <img
                alt=""
                src={comp2}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div className="storyImage11">
              <img
                style={{
                  height: "100%",
                  width: "100%",
                }}
                alt=""
                src={comp}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <img src={comingsoon}/>
        </div>
      </div>
    </div>

  );
};

export default Story;
