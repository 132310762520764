import logo from "../../assets/logo-white.png";
import insta from "../../assets/Instagram.svg";
import tweet from "../../assets/Twitter.svg";
import link from "../../assets/LinkedIn.svg";
import fb from "../../assets/Facebook.svg";
import "./footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-lg-4 about-footer">
              <Link to={"/"}>
                <img src={logo} />
              </Link>
              <ul>
                <li>
                  <p>
                  KlayFX is a trusted trading firm with a strong history of successful transactions.
                  </p>
                  <p>
                  Please note that KlayFX does not offer services to residents of the EU, USA, Canada, Belgium, or FATF Blacklisted countries. For more details, please visit the Account Opening section in our FAQs.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-2 page-more-info mb-0">
              <div className="footer-title">
                <h4>Sitemap</h4>
              </div>
              <ul className="mb-0">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/forex">Forex</Link>
                </li>
                <li>
                  <Link to="/indices">Indices</Link>
                </li>
                <li>
                  <Link to="/cfds">CFD</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-6 col-lg-2 page-more-info rrf">
              <div className="footer-title"></div>
              <ul>
                <li>
                  <Link to="/crypto">Cryptocurrencies</Link>
                </li>
                <li>
                  <Link to="/platform">KlayFX Platform</Link>
                </li>
                <li>
                  <Link to="/account-type">Account types </Link>
                </li>
                <li>
                  <Link to="/account-funding">Account funding</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-2 page-more-info">
              <div className="footer-title">
                <h4>Policies</h4>
              </div>

              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Refund policy</Link>
                </li>
                <li>
                  <Link to="/aml-policy">AML policy</Link>
                </li>
                <li>
                  <Link to="/kyc-policy">KYC policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-xs-1 page-more-info social-logo">
              <div className="footer-logo">
                <Link to="">
                  <i
                    className="fa fa-instagram fafa_icon_size"
                    aria-hidden="true"></i>
                </Link>
                <br />
                <Link to="">
                  <i
                    className="fa fa-twitter fafa_icon_size"
                    aria-hidden="true"></i>
                </Link>
                <br />
                <Link to="">
                  <i
                    className="fa fa-facebook-official fafa_icon_size"
                    aria-hidden="true"></i>
                </Link>
                <br />
                <Link to="">
                  <i
                    className="fa fa-linkedin-square fafa_icon_size"
                    aria-hidden="true"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="footer-bottom">
          <div className="row">
            <div className="col-sm-12">
              <p>
                Copyright © {new Date().getFullYear()} KlayFx. All
                Rights&nbsp;Reserved.
              </p>

              {/* <p><b>RISK WARNING:</b> Forex and CFDs are leveraged products and involve a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary.</p> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
