import axios from "axios";
import {
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
  GET_BLOG_REQUEST,
} from "../constants/BlogConstants";

export const getBlogApi = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BLOG_REQUEST });
    const { data } = await axios.get(`https://tide.fxleaders.com/d5/index.php`);

    dispatch({
      type: GET_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BLOG_FAILURE,
      payload: error.message,
    });
  }
};
