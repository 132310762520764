import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import Footer from "./components/Footer/Footer";
import AboutUs from "./pages/AboutUsPage";
import ContactPage from "./pages/ContactUsPPage";
import Signup from "./pages/Signup";
import ForexPage from "./pages/ForexPage";
import IndecesPage from "./pages/IndecesPage";
import Cfcs from "./pages/Cfcs";
import CryptoPage from "./pages/CryptoPage";
import MetaTradePage from "./pages/MetaTradePage";
import AccountPricingPage from "./pages/AccountPricingPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import RefundPolicyPage from "./pages/RefundPolicyPage";
import AMLPolicy from "./pages/AMLPolicy";
import KYCPolicy from "./pages/KYCPolicy";
import AccountFundingPage from "./pages/AccountFundingPage";
import BlogsCard from "./components/Blogs/BlogsCard";
import BlogDetails from "./components/Blogs/BlogDetails";
import Loader from "./components/Loader/Loader";
import ScrollTop from "./ScrollTop";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/forex" element={<ForexPage />} />
          <Route path="/indices" element={<IndecesPage />} />
          <Route path="/cfds" element={<Cfcs />} />
          <Route path="/crypto" element={<CryptoPage />} />
          <Route path="/platform" element={<MetaTradePage />} />
          <Route path="/account-type" element={<AccountPricingPage />} />
          <Route path="/account-funding" element={<AccountFundingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
          <Route path="/aml-policy" element={<AMLPolicy />} />
          <Route path="/kyc-policy" element={<KYCPolicy />} />
          <Route path="/blogs" element={<BlogsCard />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/loader" element={<Loader />} />
        </Routes>
        <Footer />
        <ScrollTop />
      </Router>
    </div>
  );
}

export default App;
