import "./DynamicPricing.css";
import done from "../../assets/small-check-icon.svg.svg";
import { Link } from "react-router-dom";
const DynamicPricing = () => {
  return (
    <div className="dynamicPricing">
      <div className="dynamicPricingText1">
        <b className="accountTypes">Account types</b>
        <div className="accountTypes1">Account types</div>
      </div>
      {/* <div className="accountTypePlans1"> */}
      <div className="row gy-4">
       

        {/* <div className="accountTypePlansChild" /> */}
        <div className="col-lg-4 col-md-6">
          <div className="ultimate">
            <div className="atpricingText">
              <div className="atpricingHeading">
                <div className="ultimate1">STANDARD</div>
                <div className="loremIpsumDolor3">
                Includes all benefits of our Standard plan:

                </div>
              </div>
              <div className="atpriceBold1">
                <div className="priceatp">
                  <div className="divatp">$</div>
                  <div className="div1atp">1</div>
                </div>
                <div className="minimumDeposit3">minimum deposit</div>
              </div>
              <Link
                className="openDemo"
                to="#">
                <div className="openDemoAccount1 fontss">Open account</div>
              </Link>
            </div>
            <div className="ultimateChild" />
            <div className="atpricingContent">
              <div className="allTheAdvantages3">
                All the advantages of Standard plan:
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Minimum Deposit - $1</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Swap free Account- yes
                </div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Spread starts from - 1.8 pips</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Minimum trading volume- 0.01
                </div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Commission - No</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Leverage - 1:500</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                  Stop out- 40%
                </div>
              </div>
            </div>
            <div className="atPricingType">
              <div className="beginners">Best</div>
            </div>
          </div>
        </div>

        {/* <div className="accountTypePlansChild" /> */}
        <div className="col-lg-4 col-md-6">
          <div className="ultimate">
            <div className="atpricingText">
              <div className="atpricingHeading">
                <div className="ultimate1">PRO</div>
                <div className="loremIpsumDolor3">
                All the features of our Pro plan:

                </div>
              </div>
              <div className="atpriceBold1">
                <div className="priceatp">
                  <div className="divatp">$</div>
                  <div className="div1atp">1000</div>
                </div>
                <div className="minimumDeposit3">minimum deposit</div>
              </div>
              <Link
                className="openDemo"
                to="#">
                <div className="openDemoAccount1 fontss">Open account</div>
              </Link>
            </div>
            <div className="ultimateChild" />
            <div className="atpricingContent">
              <div className="allTheAdvantages3">
                All the advantages of Pro plan:
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Minimum Deposit - $1000</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Swap free Account- yes
                </div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Spread starts from - 1 pips</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Minimum trading volume- 0.01
                </div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Commission - No</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Leverage - 1:500</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Stop out- 40%
                </div>
              </div>
            </div>
            <div className="atPricingType">
              <div className="beginners">Expert</div>
            </div>
          </div>
        </div>

        {/* <div className="accountTypePlansChild" /> */}
        <div className="col-lg-4 col-md-6">
          <div className="ultimate">
            <div className="atpricingText">
              <div className="atpricingHeading">
                <div className="ultimate1">ECN</div>
                <div className="loremIpsumDolor3">
                Exclusive benefits for high-volume traders:

                </div>
              </div>
              <div className="atpriceBold1">
                <div className="priceatp">
                  <div className="divatp">$</div>
                  <div className="div1atp">25,00</div>
                </div>
                <div className="minimumDeposit3">minimum deposit</div>
              </div>
              <Link
                className="openDemo"
                to="https://area.lykostrading.com/register">
                <div className="openDemoAccount1 fontss">Open account</div>
              </Link>
            </div>
            <div className="ultimateChild" />
            <div className="atpricingContent">
              <div className="allTheAdvantages3">
                All the advantages of ECN plan:
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Minimum Deposit - $2500</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Swap free Account- No
                </div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Spread starts from- Raw</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Minimum trading volume- 0.01
                </div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Commission- $6</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">Leverage - 1:500</div>
              </div>
              <div className="atPricingPoints">
                <img className="doneIcon21" alt="" src={done} />
                <div className="minimumDeposit4">
                Stop out- 40%
                </div>
              </div>
            </div>
            <div className="atPricingType">
              <div className="beginners">Expert</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicPricing;
