import React from 'react'

const AMLPolicy = () => {
  return (
    <div className='containerpp'>
    <div className='aCompanyYouCanTrustParent'>
          <b className='aCompanyYou1'>A company you can trust</b>
          <div className='privacyPolicy'>AML Policy</div>
    </div>
    <p>KlayFX is required to comply with the Anti-Money Laundering and Countering Financing of Terrorism Legislation (AML/CTF Laws). To help the government fight the funding of terrorism and money laundering activities, law requires all financial institutions to obtain, verify, and record information that identifies each person opening an account. KlayFX has developed internal Anti-Money laundering and Counter-Terrorism Policy (hereinafter – AML Policy) based on the risk assessment, so the objectives of the AML/CFT Laws can be achieved. These are:.</p>

   
    <ul>
   
        <li>To detect and deter money laundering and financing of terrorism;</li>
        <hr/>
        <li>To maintain and enhance KlayFX international reputation by adopting, where appropriate, recommendations issued by the Financial Action Task Force; and</li>
        <hr/>
        <li>To contribute to public confidence in the financial system.</li>
        <hr/>
    
       

    </ul>

    <h5>By applying for an account with KlayFX you are taken to agree to the following terms:</h5>
    <ul>
   
   <li>You warrant that you comply with all applicable anti-money laundering laws and regulations, including but not limited to the AML/CTF Laws and associated rules and regulations (in force from time to time);</li>
   <hr/>

   <li>You are not aware and have no reason to suspect that:
   <ul>
    <li>The money used to fund your deposit in your account has been or will be derived from or related to any money laundering or other activities deemed illegal under applicable laws or regulations or otherwise prohibited under any international convention or agreement (“illegal activities”); or

    <li>The proceeds of your investment in the Fund will be used to finance illegal activities; and</li>
    <li>
You agree to promptly provide us with all information that we reasonably request in order to comply with all applicable laws and regulations relating to anti-money laundering.</li>

    </li>
   </ul>
   </li>
   <hr/>

  

</ul>

    <p>As per the AML Policy KlayFX will conduct initial and ongoing due diligence for each customer according to risk level posed by the customer.</p>
  <p><b>What this means for you:</b> In compliance with the AML/CFT legislation and KlayFX AML Policy, KlayFX will ask for certain minimum identification information from each customer which opens an account; record customer identification information and the verification methods and results; provide notice to customers that we will seek identification information and compare customer identification information with government-provided lists of suspected terrorists.</p>
    <p>Please note that if we will not receive the required documents on file, your pending withdrawals will be cancelled and credited back to your trading account. We will notify you on such event via our system.</p>
    
    <h5>This minimum information may include:</h5>
    <h6>For natural persons:</h6>
    <ul>
        <li>Full name, date of birth, nationality, current permanent address,</li>
        <hr/>
        <li>If the person is not the customer, the person's relationship to the customer,</li>
        <hr/>
        <li>The origin of the funds to be used for trading,</li>
        <hr/>
        <li>Occupation and name of employer (if self-employed, the nature of the self-employment).</li>
        <hr/>
    </ul>
    <h6>For legal entities:</h6>
    <ul>
        <li>Full legal name, identifier or registration number, current permanent address and/or registered office,</li>
        <hr/>
        <li>Full list of shareholders and beneficiaries, their names, registration numbers, addresses,</li>
        <hr/>
        <li>List of beneficial owners and their details as requested by KlayFX for natural persons,</li>
        <hr/>
        <li>The origin of the funds to be used for trading,</li>
        <hr/>
        <li>Purpose of opening accounts.</li>
        <hr/>
    </ul>
    <p>In order to verify the above mentioned information KlayFX will require submitting the following documents:</p>

    <h6>For legal entities:</h6>
    <ul>
        <li>Current valid passport;</li>
        <hr/>
        <li>National identity card;</li>
        <hr/>
        <li>Armed forces identity card; and</li>
        <hr/>
        <li>Driving license which bears a photograph; and</li>
        <hr/>
        <li>Document proving current permanent address (such as utility bills, bank statements, etc.).</li>
        <hr/>
    </ul>


    <h6>For legal entities:</h6>
    <ul>
        <li>Certificate of Incorporation or any national equivalent;</li>
        <hr/>
        <li>Memorandum and Articles of Association and statutory statement or any national equivalent;</li>
        <hr/>
        <li>Certificate of good standing or other proof of registered address of the company;</li>
        <hr/>
        <li>Resolution of the board of directors to open an account and confer authority on those who will operate it;</li>
        <hr/>
        <li>Copies of powers of attorney or other authorities given by the directors in relation to the company;</li>
        <hr/>
        <li>Proof of identity of directors in case he/she will deal with KlayFX on behalf of the Customer (according to the Individual identity verification rules described above);</li>
        <hr/>
        <li>Proof of identity of the beneficial owner(s) and/or the person(s) on whose instructions the signatories on the account are empowered to act (according to the Individual identity verification rules described above).</li>
        <hr/>
    </ul>
    <p>In order to verify the above mentioned information KlayFX will require at minimum submitting the documents specified above for corporate customers. KlayFX may also request you to provide additional information accompanied with respective documents.</p>
   <p>KlayFX may at its sole discretion perform periodic customers due diligence and request them to update the information and documents needed by KlayFX to comply with applicable AML/CFT regulations and internal AML Policy.</p>
   <p>We expect that you provide us with the information and documents requested within 10 business days from the moment of respective request receipt. Once you provided us with all information and documents requested KlayFX will perform KYC and CDD procedures within 10 business days and approve (verify) your account. If you failed to comply with the KYC CDD obligations KlayFX may reject opening of an account for you or limit an access to your account.</p>
   </div>
  )
}

export default AMLPolicy
