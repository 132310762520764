import ForexCarosel from "../ForexCarosel/ForexCarosel";
import "./discover.css";
import ff from "../../assets/Sign up fasr.svg";
import sm from "../../assets/Start small.svg";
import fav from "../../assets/Find your favourites.svg";
import ForexTradingViews from "../ForexTradingViews";
const DiscoverForex = () => {
  return (
    <div className="discoverForex row">
      <div className="discoverSidebar col-lg-6 col-md-12">
        <div className="discoverTitle">
          <div className="textdd">
            <div className="discoverForexParent">
              <b className="discoverForex1">Forex Trading
              </b>
              <div className="tradeTheMost1">Trade Major & Minor Currencies</div>
            </div>
            <div className="tradeAudchfEurchf">
            Begin Your First Trade with Simple Steps
            </div>
          </div>
        </div>
        <div className="sidebarContent1">
          <div className="startSmall">
            <img className="signUpFasr1" alt="" src={ff} />
            <div className="textd2">
              <div className="signUpFast3">Quick Sign-Up</div>
              <div className="tradeAudchfEurchf">
              Sign up quickly—almost as fast as ordering takeout.

              </div>
            </div>
          </div>
          <div className="sidebarContentChild" />
          <div className="startSmall">
            <img className="signUpFasr1" alt="" src={sm} />
            <div className="textd2">
              <div className="signUpFast3">Start small</div>
              <div className="tradeAudchfEurchf">
              You don’t need to invest a lot—begin with just $100.
              </div>
            </div>
          </div>
          <div className="sidebarContentChild" />
          <div className="startSmall">
            <img className="signUpFasr1" alt="" src={fav} />
            <div className="textd2">
              <div className="signUpFast3">Explore Your All Currency Pairs</div>
              <div className="tradeAudchfEurchf">
              Trade popular pairs like AUDCHF, EURCHF, CADJPY, AUDNZD, CHFJPY, EURGBP, and more.
              </div>
            </div>
          </div>
        </div>
        <button className="getStartButton1">Get started</button>
      </div>
      <div className="curoselwraper col-lg-6  clo-md-12 overflow-y-hidden">
        <ForexTradingViews />
      </div>
    </div>
  );
};

export default DiscoverForex;
