import React from 'react'
import trust from "../assets/Trust.svg"
import dd from "../assets/Downloads.svg"
import Hero from '../components/Hero/Hero'
import BuildPortfolio from '../components/Portfolio/PortFolio'
import WhyUs from '../components/WhyUS/Whyus'
import MT4 from '../components/Mt4/MetaT'
import Features from '../components/Features/Features'
import ContactUs from '../components/contactus/contactus'
import Footer from '../components/Footer/Footer'
const HomePage = () => {
  return (
  <>
    <Hero/>
    <BuildPortfolio/>
    <WhyUs/>
    <MT4 head='Metatrader 4' />
    <Features/>
    <ContactUs/>
   
  </>
  )
}

export default HomePage
