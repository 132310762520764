import "./MT4.css";
import sam from "../../assets/Samsung mockup.png";
import ip from "../../assets/iPhone mockup.png";
import App from "../../assets/appstore.com svg.svg";
import win from "../../assets/browser-01.png";
import play from "../../assets/Frame 1000002185.svg";
import lap from "../../assets/laptop.png";
import ios from "../../assets/IMG_7669.PNG"
import and from "../../assets/androidphone.png"

const MT4 = ({ head }) => {

  return (
    <div className="mt4">
      <div className="metahead">
        <b className="metatrader4">KlayFX Platform</b>
        <div className="metasubtitle">
          <div className="experienceSeamlessTrading1">
          KlayFX Platform: Your Tool for Better Trading

          </div>
          <div className="dueToIts1">
           KlayFX Platform offers a seamless trading experience with its advanced features. As a leading trading platform, KlayFX  provides powerful tools for traders of all levels. Benefit from enhanced analysis capabilities, flexible trading systems, and a range of other features to support your trading strategy.

          </div>
        </div>
      </div>
      <div className="platforms">
        <div className="windows">
          <div className="windowsImage">
            <div className="laptop">
              <div className="bottom">
                {/* <div className="shadow2" />
                <div className="shadow3" /> */}
                {/* <div className="deviceKeyboard1" /> */}
              </div>
              <div className="top">
                {/* <div className="deviceBack1" />
                <div className="deviceFrame1" /> */}
                <div className="screenPlaceYourVisual1">
                  <img className="image60Icon" alt="" src={lap} />
                </div>
                {/* <div className="deviceScreenShadow1" />
                <div className="screenGlow1" /> */}
              </div>
            </div>
          </div>
          <div className="windowsMockup">
            <div className="text">
              <img className="textChild" alt="" src={win} />
              <div className="osX">Web</div>
            </div>
            <button className="getStartButton1">Browse</button>
          </div>
        </div>
        <div className="iosAndroid">
          <div className="osXIos">
            <div className="windowsMockup">
              <div className="text">
                <img className="textChild" alt="" src={App} />
                <div className="osX">{`OS X & iOS`}</div>
              </div>
              <button className="getStartButton1">Download</button>
            </div>
            <img className="iphoneMockupIcon" alt="" src={ios} />
          </div>
          <div className="osXIos">
            <div className="windowsMockup">
              <div className="text">
                <img className="textChild" alt="" src={play} />
                <div className="osX">Android</div>
              </div>
              {/* <button onClick={downloadFile} className="getStartButton1">Download</button> */}
              <a href="https://klayfx.com/klayfx.apk" className="getStartButton1"> Download</a>
            </div>
            <img className="samsungMockupIcon" alt="" src={and} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MT4;
