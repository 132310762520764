import React from 'react'
import Header from '../components/Header'
import AboutHero from '../components/AboutHero/AboutHero'
import AbouFaQ from '../components/AboutFaq/AboutFaq'
import WhyTradeForex from '../components/WhyTrade/WhyTrade'
import ContactUs from '../components/contactus/contactus'
import Story from '../components/OurStories/Story'

const AboutUs = () => {
  return (
    <>
    <AboutHero/>
    <AbouFaQ/>
    <WhyTradeForex/>
    <Story/>
    <ContactUs/>

    
    </>
  )
}

export default AboutUs
