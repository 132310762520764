
import settings from '../../assets/Frame 1000002733.svg'
import help from '../../assets/medal 1.svg'
import hand from '../../assets/platform 2.svg'
import high from '../../assets/Forex.svg'

const CfdEnhancement = () => {
  	
  	return (
        <div className=''>
    		<div className='enhancement '>
      			<div className='enttitle'>
        				<b className='enhancement1'>CFD TRADING
						</b>
        				<div className='howToEnhanceContainer'>
          					<p className='atLykosTrading'>CFD Trading with KlayFx
							  </p>
          				
        				</div>
      			</div>
      			<div className='enhacementcards '>
        				<div className='encards '>
          					<div className='encard'>
            						<img className='settings2Icon1' alt="" src={settings} />
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Great Pricing
										  </div>
              							<div className='ownSharesInContainer'>Trade US stock CFDs starting at just USD 100 and the US Wall St 30 from 2 points.</div>
            						</div>
          					</div>
          					<div className='encard'>
            						<img className='highLiquidityIcon1' alt="" src={high}/>
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Wide Range of CFDs</div>
              							<div className='ownSharesInContainer'>
                								<p className='atLykosTrading'>Access over 8,800 CFDs, including stocks, indices, forex, commodities, options, and bonds.</p>
                							
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className='encards '>
          					<div className='encard'>
            						<img className='settings2Icon1' alt="" src={hand} />
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Top-Rated Platform
										  </div>
              							<div className='ownSharesInContainer'>Enjoy our award-winning platform with integrated trade signals, news feeds, and advanced risk management tools.</div>
            						</div>
          					</div>
          					<div className='encard'>
            						<img className='settings2Icon1' alt="" src={help} />
            						<div className='encardtitle'>
              							<div className='investAutomatically'>Trusted Expertise
										  </div>
              							<div className='ownSharesInContainer'>Join over 1,000,000 satisfied customers and benefit from KlayFx 30 years of top-notch service.

</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
            </div>);
};

export default CfdEnhancement;
