import "./forexhro.css";
import ihpone from "../../assets/Hero IMage.png";
import swit from "../../assets/switzerland.png";
import euro from "../../assets/european union.svg";
import aus from "../../assets/australia.svg";
import { Link } from "react-router-dom";
const ForexHero = () => {
  return (
    <div className="forexHero row">
      <div className="heroImage col-lg-12 col-md-12 ">
        <img className="iphone13ProMockups111Icon" alt="" src={ihpone} />
      </div>
      <div className="forexHeading col-lg-12 col-md-12">
        <div className="text6">
          <div className="subtitle">
            <b className="aboutUs">Trading Forex</b>
            <div className="tradeOnYourContainer">
              <p className="yourTerms">
              Trade Forex Anytime, Anywhere
              </p>
            </div>
          </div>
          <div className="weHave2471">
          Access global currency markets with ease and real-time data for better trading decisions.
          </div>
        </div>
        <Link
          className="getStartButton1"
          to="#">
          Get started
        </Link>
      </div>
    </div>
  );
};

export default ForexHero;
