
import  './cryptoProtection.css';
import img from '../../assets/Protection  Image.png'
import check from '../../assets/accept 1.svg'
const CryptoProtection = () => {
  	
  	return (
    		<div className='cryptoProtection'>
      			<div className='protectioncontentcr'>
        				<div className='protectionheadcr'>
          					<b className='protectioncr'>Security</b>
          					<div className='protectionForYour1'>Keep Your Coins Safe with Us
							  </div>
        				</div>
        				<div className='protectiontilecr'>
          					<div className='tile'>
            						<img className='accept1Icon4' alt="" src={check} />
            						<div className='industryLeadingSecurity'>Enjoy industry-leading security for your cryptocurrency investments.</div>
          					</div>
          					<div className='tile'>
            						<img className='accept1Icon4' alt="" src={check} />
            						<div className='industryLeadingSecurity'>Retain complete control over your coins.</div>
          					</div>
          					<div className='tile'>
            						<img className='accept1Icon4' alt="" src={check} />
            						<div className='industryLeadingSecurity'>Most of our customers' coins are stored securely offline.</div>
          					</div>
          					<div className='tile'>
            						<img className='accept1Icon4' alt="" src={check} />
            						<div className='industryLeadingSecurity'>Benefit from crime insurance protecting against theft and cybersecurity threats.
									</div>
          					</div>
        				</div>
      			</div>
      			<img className='protectionImageCr1' alt="" src={img} />
    		</div>);
};

export default CryptoProtection;
