import "./IndecesFeatures.css";

import ind from "../../assets/Indices.svg";
import hrc from "../../assets/24 hour market.svg";
import buy from "../../assets/buy 1.svg";
import medal from "../../assets/medal 1.svg";
import high from "../../assets/High liquidity.svg";
import support from "../../assets/help-desk 1.svg";

const IndecesFeatures = () => {
  return (
    <div className="whyUs">
      <div className="whyushead">
        <b className="whyUs1">Features</b>
        <div className="whyUsTitle1">
          <div className="whyChooseTrading1">
          Now Trade Indices Effortlessly     
          </div>
        </div>
      </div>
      <div className="whyuscards">
        <div className="cardsrow1 row gy-4">
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="forexIcon1" alt="" src={ind} />
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">All Your Needs in One Place</div>
                <div className="eurusdUsdjpyGbpusd">
                Trade over 80 global indices, including the FTSE 100, with ease.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={hrc} />
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Trade Around the Clock
                </div>
                <div className="eurusdUsdjpyGbpusd">
                Enjoy 24/5 trading on popular indices like the UK 100 and US 30, so you can trade even when the markets are closed.

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="forexIcon1" alt="" src={buy} />
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">No partial fills</div>
                <div className="eurusdUsdjpyGbpusd">
                Experience seamless trading with no partial fills and no dealer intervention, no matter the size of your trades.

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={high} />
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Accurate Pricing</div>
                <div className="eurusdUsdjpyGbpusd">We provide the most precise bid/ask prices by combining multiple feeds from top banks.

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={support} />
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Top-Notch Support</div>
                <div className="eurusdUsdjpyGbpusd">
                Benefit from our award-winning customer service available online 24/5.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ww">
              <div className="cardimage">
                <img className="indicesIcon1" alt="" src={medal} />
              </div>
              <div className="cardsubtitle">
                <div className="forexwhy">Exclusive Access</div>
                <div className="eurusdUsdjpyGbpusd">
                Trade with our exclusive share baskets for broader market exposure in a single position.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndecesFeatures;
