import './fundingHero.css'
import ca from '../../assets/Create account.svg'
import help from '../../assets/help-desk 1.svg'
import fun from '../../assets/Fund.svg'
import tr from '../../assets/Trade.svg'
const AccountFundingHero = () => {
  	
  	return (
    		<div className='accountfundingHero'>
      			<div className='accountfundingContent'>
        				<div className='fundingtext'>
          					<div className='fundingsubtitle'>
            						<div className='fundingheading'>
              							<b className='investInYourself'>Invest in Your Trading Journey</b>
              							<div className='fundingOptions'>Easy & Fast Deposit Methods
                                                        </div>
            						</div>
            						<div className='atLykosCustomers1'>At KlayFX, we offer a range of convenient funding methods to improve your trading experience. Choose from options like e-wallets, bank transfers, and PayPal. This flexibility ensures you can deposit and withdraw funds easily, customized to your preferences, for a smooth and efficient trading process.</div>
          					</div>
                              <div className='sidebarcoloumn'>
                                  <div className='createAccount'>
                                        <img className='createAccountIcon1' alt="" src={ca} />
                                        <div className='textport'>
                                              <div className='createAccount1'>Flexible Methods
                                              </div>
                                              <div className='startYourFirst1'>Deposit using bank transfers, e-wallets, or PayPal.
                                              </div>
                                        </div>
                                  </div>
                                  <div className='sidebarcoloumnChild' />
                                  <div className='createAccount'>
                                        <img className='createAccountIcon1' alt="" src={fun} />
                                        <div className='textport'>
                                              <div className='createAccount1'>Fast Deposits
                                              </div>
                                              <div className='startYourFirst1'>Quick processing for e-wallets and PayPal</div>
                                        </div>
                                  </div>
                               
                                  <div className='sidebarcoloumnInner' />
                                  <div className='createAccount'>
                                        <img className='createAccountIcon1' alt="" src={tr} />
                                        <div className='textport'>
                                              <div className='createAccount1'>Easy Transactions
                                              </div>
                                              <div className='startYourFirst1'>Simple and efficient deposit process.</div>
                                        </div>
                                  </div>
                            </div>
        				</div>
        				<div className='fundinghelpcard'>
          					<div className='fundinghelpText'>
            						<img className='createAccountIcon1' alt="" src={help}/>
            						<div className='helpSubtitle'>
              							<div className='lookingForHelp1'>Looking for help?</div>
                								<div className='signUpAnd23'>KlayFx takes great pride in providing our customers with exceptional support and guidance, ensuring their success in every way possible. Our team of dedicated client support professionals is available 24/5 to offer assistance whenever needed, ensuring that our customers always have access to the help they require.</div>
                								</div>
                								</div>
                                                <button className='getStartButton1'>
                                                Start learning
                            </button>
                								</div>
                								</div>
                								</div>);
              							};
              							
              							export default AccountFundingHero;
              							