import React from "react";
import trust from "../../assets/Trust.svg";
import dd from "../../assets/Downloads.svg";
// import line from "../../assets/Line 70.png";
import mm from "../../assets/MOCKUP-01.png";
import mmm from "../../assets/crop-mockup.png";
import "./hero.css";
import { Link } from "react-router-dom";

import Carousel from "react-bootstrap/Carousel";

const Hero = () => {
  return (
    <div className="hero">
      <div className="statsParent">
        <div className="stats">
          <div className="download">
            <img className="downloadsIcon1" alt="" src={dd} />
            <div className="downloads"> Join Our Trusted Clients</div>
          </div>
          <div className="statsChild" />
          <div className="download">
            <img className="trustIcon1" alt="" src={trust} />
            <div className="downloads"> Be Part of Our Success</div>
          </div>
        </div>

        <Carousel className="hero-carousel" interval={1500} pause={false}>
          <Carousel.Item>
            <div className="d-flex flex-column gap-4">
              <div className="heroheading">
                <div className="tradingInForexContainer">
                  <p className="metalsIndices">
                  Advanced Trading Solutions for Pro Trading for Pro Trading{" "}
                    {/* <span className="d-lg-block"> for Pro Trading</span> */}
                  </p>
                </div>
                <div className="youDoLife1">Tools for your winning edge.
                </div>
              </div>
              <div className="buttons justify-content-center">

                <Link
                  className="openLive"
                  to="https://my.klayfx.com/register">
                  Open live account
                </Link>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex flex-column gap-4">
              <div className="heroheading">
                <div className="tradingInForexContainer">
                  <p className="metalsIndices">
                    Trade Currencies with {" "}
                    <span className="d-lg-block">Fast Execution</span>
                  </p>
                </div>
                <div className="youDoLife1">Quick trades, big gains</div>
              </div>
              <div className="buttons justify-content-center">
    
                <Link
                  className="openLive"
                  to="https://my.klayfx.com/register">
                  Open live account
                </Link>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex flex-column gap-4">
              <div className="heroheading">
                <div className="tradingInForexContainer">
                  <p className="metalsIndices">
                  Low Spreads, High Returns {" "}
                    <span className="d-lg-block">Trade Smarter</span>
                  </p>
                </div>
                <div className="youDoLife1">Maximize profits with every trade.
                </div>
              </div>
              <div className="buttons justify-content-center">

                <Link
                  className="openLive"
                  to="https://my.klayfx.com/register">
                  Open live account
                </Link>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <img className="mockupsIcon" alt="" src={mmm} />
    </div>
  );
};

export default Hero;
