import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./faq.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";


const FAQ = ({data}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const results = data.filter((item) =>
      item.question.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm]);

  return (
    <>
      <div className="faqheading mar">
        <b className="faqs">FAQS</b>
        <div className="gotMoreQuestions1">
          Got more questions? We’ve got answers.
        </div>
      </div>

      <div className="faq ">
        {searchResults.map((item) => (
          <Question
            key={item.id}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    </>
  );
};

const Question = ({ question, answer }) => {
  const [isActive, setActive] = useState(false);

  const handleClick = () => {
    setActive(!isActive);
  };

  return (
    <div className="question-wrapper">
      <div className="question">
        <h5>{question}</h5>
        <button onClick={handleClick}>
          {isActive ? (
            <FontAwesomeIcon icon={faAngleUp} className="icon" />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} className="icon" />
          )}
        </button>
      </div>
      <div className={isActive ? "answer active" : "answer"}>{answer}</div>
    </div>
  );
};

export default FAQ;
