import "./portfolio.css";
import ca from "../../assets/Create account.svg";
import ver from "../../assets/Verify.svg";
import fun from "../../assets/Fund.svg";
import tr from "../../assets/Trade.svg";
import app from "../../assets/Apple.svg";
import curr from "../../assets/currentDot.png";
import fur from "../../assets/future dot.png";
import gg from "../../assets/greendotgraph.png";
import gb from "../../assets/Graphbluedot.png";
import vec from "../../assets/GG.png";
import { generatePath } from "react-router";
// import grp from "../../assets/ImageGrap.png";
import { Link } from "react-router-dom";
import BuildPortfolioTradingView from "../BuildPortfolioTradingView";

const BuildPortfolio = () => {
  return (
    <div className="buildPortfolio">
      <div className="portfoliosidebar">
        <div className="portfoliosiderbarheading">
          <div className="buildPortfolio1">
            <b className="buildPortfolio2">How to start trading?
            </b>
            <div className="buildYourPortfolio1">Get Started in 4 Easy Steps</div>
          </div>
          <div className="startYourFirst1">
          Follow these easy steps to execute your first trade: 

          </div>
        </div>
        <div className="sidebarcoloumn">
          <div className="createAccount">
            <img className="createAccountIcon1" alt="" src={ca} />
            <div className="textport">
              <div className="createAccount1">Open Account</div>
              <div className="startYourFirst1">
              Sign up and open your trading account with KlayFx.
              </div>
            </div>
          </div>
          <div className="sidebarcoloumnChild" />
          <div className="createAccount">
            <img className="createAccountIcon1" alt="" src={ver} />
            <div className="textport">
              <div className="createAccount1">Verify Yourself

              </div>
              <div className="startYourFirst1">
              Upload your documents to confirm your account with KlayFx.

              </div>
            </div>
          </div>
          <div className="sidebarcoloumnChild" />
          <div className="createAccount">
            <img className="fundIcon1" alt="" src={fun} />
            <div className="textport">
              <div className="createAccount1">Fund Your Account
              </div>
              <div className="startYourFirst1">{`Log in to KlayFx  and add money to your account.`}</div>
            </div>
          </div>
          <div className="sidebarcoloumnInner" />
          <div className="createAccount">
            <img className="createAccountIcon1" alt="" src={tr} />
            <div className="textport">
              <div className="createAccount1">Execute Your Trades
              </div>
              <div className="startYourFirst1">
              Start trading easily with a variety of financial products.
              </div>
            </div>
          </div>
        </div>
        <Link
          className="getStartButton1"
          to="#">
          Get started
        </Link>
      </div>
      <div className="portfolioImage">
        <div className="imagecontent">
          <div className="findTheWinners1">Never miss an opportunity</div>
          <div className="withForecastsOn1">{`Stay updated with market trends`}</div>
        </div>
        <div className="imagegrap overflow-y-hidden m-3">
          {/* <img className="graphImage" src={grp} /> */}
          <BuildPortfolioTradingView />
        </div>
      </div>
    </div>
  );
};

export default BuildPortfolio;
