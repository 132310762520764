import vid from "../../assets/Video tutorial.svg";
import risk from "../../assets/Risk management.svg";
import easy from "../../assets/Easy to use.svg";
import trad from "../../assets/Trading tools.svg";
import fund from "../../assets/Fundamental analysis.svg";
import tech from "../../assets/Technical analysis.svg";
import "./features.css";
const Features = () => {
  return (
    <div className="features">
      <div className="featurehead">
        <b className="features1">Features</b>
        <div className="subtitlefeature">
          <div className="unleashYourPotentialContainer">
            <p className="ourPlatform">Explore how our platform can help you
              achieve your trading goals.
            </p>
            {/* <p className="ourPlatform">our platform.</p> */}
          </div>
        </div>
      </div>
      <div className="features2">
        <div className="row gy-4">
          <div className="col-md-6 col-lg-4">
            <div className="featurecard">
              <div className="featurecardtitle">
                <img className="easyToUse2" alt="" src={easy} />
                <div className="fundamentalAnalysis">Intuitive </div>
              </div>
              <div className="lykosProvidesA1">
                KlayFx provides a simple and intuitive trading experience. Manage your account easily from both mobile and web, giving you flexibility to trade anytime, anywhere. Whether you're traveling or relaxing at home, our platform adapts to your needs.

              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="featurecard">
              <div className="featurecardtitle">
                <img className="easyToUse2" alt="" src={fund} />
                <div className="fundamentalAnalysis">Fundamental Analysis</div>
              </div>
              <div className="lykosProvidesA1">

                Evaluate the true value of markets with our comprehensive fundamental analysis tools. These tools help you understand market trends and make informed decisions, improving your chances of achieving financial success.

              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="featurecard">
              <div className="featurecardtitle">
                <img className="tradingToolsIcon1" alt="" src={trad} />
                <div className="fundamentalAnalysis">Trading tools</div>
              </div>
              <div className="lykosProvidesA1">
                Utilize our advanced trading tools designed to help you maximize profits and manage risks. Features include customizable charts to track market movements, detailed technical indicators for precise analysis, and real-time news updates to keep you informed.

              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="featurecard">
              <div className="featurecardtitle">
                <img className="easyToUse2" alt="" src={risk} />
                <div className="fundamentalAnalysis">Risk management</div>
              </div>
              <div className="lykosProvidesA1">
                Protect your trading capital with our range of risk management tools. Use stop-loss and limit orders to set boundaries on your trades and minimize potential losses, ensuring you maintain control over your trading strategy.
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="featurecard">
              <div className="featurecardtitle">
                <img className="easyToUse2" alt="" src={tech} />
                <div className="fundamentalAnalysis">Technical analysis</div>
              </div>
              <div className="lykosProvidesA1">
                Leverage our extensive technical analysis tools to support your trading decisions. Access real-time data, use advanced charting features, and customize indicators to analyze market trends and make well-informed trades.
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="featurecard">
              <div className="featurecardtitle">
                <img className="easyToUse2" alt="" src={vid} />
                <div className="fundamentalAnalysis">
                  Educational Videos
                </div>
              </div>
              <div className="lykosProvidesA1">
                Improve your trading skills with our detailed video tutorials from professional trainers. These tutorials cover various trading techniques and strategies, providing valuable insights to help you become a more effective trader.


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
