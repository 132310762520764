import { Table } from 'react-bootstrap';
import './cfdPricing.css'
const CfdPricing = () => {
  	
  	return (
    		<div className='cfdpricing'>
      			<div className='pricecontent'>
        				<b className='pricing'>Pricing</b>
        				<div className='pricesubtitle'>
          					<div className='ultraCompetitiveCfdPricing'>Highly Competitive CFD Rates
                    </div>
          					<div className='weOfferThree1'>We provide three pricing tiers based on your account level.
                    </div>

        				</div>
      			</div>
   
    <Table striped bordered hover  responsive className='table-responsive'>
        <thead>
        <tr>
          <th>Instruments</th>
          <th>STANDARD</th>
          <th>PRO</th>
          <th>ECN</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><h6><b>US 500</b></h6>
          <p>US500.I</p>
          </td>
          <td>$10</td>
          <td>$500</td>
         <td>$10,000</td>
          <td> <button className='openLive'>
									Trade
		</button></td>
        </tr>
          <tr>
            <td> <h6><b>Germany 40</b></h6>
            <p>GER40.I</p>
            </td>
            <td>0.01</td>
            <td>0.01</td>
            <td>0.01</td>
            <td> <button className='openLive'>
									Trade
		</button></td>
          </tr>
          <tr>
          <td> <h6><b>US Tech 100 NAS</b></h6>
            <p>USNAS100.I</p>
            </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td> <button className='openLive'>
									Trade
		</button></td>
          </tr>
          <tr>
          <td> <h6><b>US 30 Wall Street</b></h6>
            <p>US30.I</p>
            </td>
            <td>1.8 pips</td>
            <td>1.1 pips</td>
            <td>0.1 pips</td>
            <td> <button className='openLive'>
									Trade
		</button></td>
          </tr>
        </tbody>
      </Table>
      			<div className='pleaseNoteThe1'>Please be aware that the prices listed above represent the minimum spreads. Actual prices may vary depending on your country of residence. For a detailed overview of exact pricing, please check the trade tickets on the platform.
            </div>
      			<div className='button4'>
        				<div className='button5'>
          					<div className='trade'>See all CFD prices</div>
        				</div>
      			</div>
    		</div>);
};

export default CfdPricing;
