import { useEffect, useState } from "react";
import { sendContactForm } from "../utils";
import ContactUs from "../components/contactus/contactus";
import { Alert } from "react-bootstrap";
const ContactPage = () => {
  // Single useState hook to manage all form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");

  // Handle form input change
  useEffect(() => {
    if (alertVisible) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000);
      // Cleanup the timeout if the component is unmounted or alert visibility changes
      return () => clearTimeout(timer);
    }
  }, [alertVisible]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendContactForm(formData);

      if (response?.data !== null) {
        setAlertMessage("Form submitted successfully!");
        setAlertVariant("success");
      } else {
        setAlertMessage("Failed to submit the form.");
        setAlertVariant("danger");
      }

      // Show the alert
      setAlertVisible(true);
      // Clear form after submission if needed
      setFormData({
        name: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      setAlertMessage("Failed to submit the form.");
      setAlertVariant("danger");
      setAlertVisible(true);
    }
  };

  return (
    <>
      <section className="contact-page-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="contacttitle" id="tileContact">
                <div className="contactheading">
                  <b className="contactUs22">Reach Out to Us</b>
                  <h3 className="lookingForHelp1" id="Heading contactUs">
                    Need Assistance?
                  </h3>
                </div>
                <p className="lykosTakesGreat1">
                  At KlayFX, we genuinely care about your success. Our friendly
                  support team is here for you 24/5, ready to provide the help
                  and guidance you need to achieve your goals. We’re always just
                  a message away, eager to support you every step of the way.
                </p>
              </div>
            </div>
          </div>
          {alertVisible && (
            <Alert
              variant={alertVariant}
              onClose={() => setAlertVisible(false)}
              dismissible
            >
              {alertMessage}
            </Alert>
          )}

          <div className="row">
            <div className="col-md-12">
              <div className="contact-page-form">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="single-input-field">
                        <input
                          type="text"
                          placeholder="Your Name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="single-input-field">
                        <input
                          type="email"
                          placeholder="E-mail"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="single-input-field">
                        <input
                          type="text"
                          placeholder="mobile Number"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="single-input-field">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 message-input">
                      <div className="single-input-field">
                        <textarea
                          placeholder="Write Your Message"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="single-input-fieldsbtn">
                      <input type="submit" value="Send Now" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
    </>
  );
};

export default ContactPage;
