import React from 'react'
import ForexHero from '../components/ForexHero/ForexHero'
import DiscoverForex from '../components/Discover Forex/Discover'
import Protection from '../components/Protection/Protection'
import CTA from '../components/CTA/Cta'
import FAQ from '../components/FAQ/FAQ'
import Enhancement from '../components/enhacment/Enhacment'
import { faqQuestionsForex } from '../constants/faq'

const ForexPage = () => {
  return (
  <>
    <ForexHero/>
    <DiscoverForex/>
    <Protection/>
    <Enhancement/>
    <FAQ data={faqQuestionsForex} />
    <CTA/>
  </>
  )
}

export default ForexPage
