import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { FaAngleDown, FaHeart } from "react-icons/fa";
import { LiaAngleDownSolid } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";

const Header = () => {
  const [expanded, setExpanded] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    setExpanded("");
  }, [pathname]);

  return (
    <Navbar expand="lg" expanded={expanded} className="bg-body-secondary">
      <Container>
        <Link className={"navbar-brand"} as={Link} to="/">
          <img src={logo} className="" />
        </Link>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="border-0"
          onClick={() => setExpanded((prev) => (prev === "" ? "expanded" : ""))}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink className={"nav-link"} to="/about" as={Link}>
              About us
            </NavLink>
            <NavDropdown title="Products" id="basic-nav-dropdown">
              <NavLink className={"dropdown-item"} to="/forex" as={Link}>
                Forex
              </NavLink>
              <NavLink className={"dropdown-item"} to="/indices" as={Link}>
                Indices
              </NavLink>
              <NavLink className={"dropdown-item"} to="cfds" as={Link}>
                CFDs
              </NavLink>

              <NavLink className={"dropdown-item"} to="/crypto" as={Link}>
                Crypto
              </NavLink>
            </NavDropdown>
            <NavLink className={"nav-link"} to="/platform" as={Link}>
              Platform
            </NavLink>
            <NavLink className={"nav-link"} to="/account-type" as={Link}>
              Account types
            </NavLink>
            <NavLink className={"nav-link"} to="account-funding" as={Link}>
              Account funding
            </NavLink>
            <NavLink className={"nav-link"} to="/blogs" as={Link}>
              Blog
            </NavLink>
            <NavLink className={"nav-link"} to="/contact" as={Link}>
              Contact us
            </NavLink>
            <Link
              target="_blank"
              className="btn34 btn btn-primary me-2"
              to="https://my.klayfx.com/">
              Login
            </Link>
            <Link
              target="_blank"
              className="btn34 btn btn-primary mt-xm-4 mb-xm-4"
              to="https://my.klayfx.com/register">
              Register
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
